import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ambafillenoire = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Amba fille noire" />
    <h3 className='underline-title'>Amba fille noire</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Amba<br />
        Fille noire<br />
        À la peau noire <br />
        De la race noire <br />
      </p>
      <p>
        Amba<br />
        Noire sur noire<br />
        Ajoutez encore noire <br />
        Noire à la troisième puissance <br />
        Noire-Noire-Noire<br />
      </p>
      <p>
        Amba<br />
        Noire pas comme les autres<br />
        Noire pas comme Titi <br />
        Titi n'a de noir que la race <br />
        Sa peau n'est pas noire <br />
      </p>
      <p>
        Amba comme Amba <br />
        Amba n'est qu'Amba<br />
        Ciza n'est pas Amba<br />
        Amba ne sera qu'Amba<br />
      </p>
      <p>
        Amba <br />
        J'aimerais te chanter <br />
        une géniale mélodie <br />
        Accompagnée de guitare électrique <br />
        À la dokta-Kassandra <br />
        Je n'en peux rien Amba<br />
        Ma voix trahit mon sentiment<br /> 
        Je me borne à épeler ton nom<br />
        Nom doux à l'Andalou <br />
        Nom poétique à l'Aziza<br />
      </p>
      <p>
        Amba<br />
        A comme Ange <br />
        M comme Matutinal <br />
        B comme Biche <br />
        A comme Attrayante<br />
      </p>
      <p>
        Amba:<br />
        Fille noire-Peau noire- Race noire<br />
      </p>
      <p>
        J'ai fouillé tous les Quillet-Larousse<br />
        Aucun de leurs mots n'est assez fort <br />
        Pour traduire ce que sent mon coeur <br />
        Leurs phrases usées s'écroulent à ton passage <br />
        Ta beauté épuise leurs mots infidèles <br />
        Seul Kassanda-dokta <br />
        Capable de te chanter avec talent: <br />
        <span className="font-italic">"Amba noire comme silure<br />
        A qui puis-je te comparer?"<br />
        "Moindu ya ngolo<br />
        Nameka yo na nani?"<br />
        </span>
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres/">Table des matières</Link>
  </Layout>
)

export default ambafillenoire
